import styled from 'styled-components'


const Container = styled.div`
  input {
    width: 16px;
    height: 16px;
    
    margin: 0;
    border: 1px solid #E9E9E9;
  }

  input[type="checkbox"] {
    accent-color: #E9E9E9;
  }

  div {
  }
`

const CheckBox = ({
  label,
  selected=false,
  onClick
}) => {

  return (
    <Container className='flex align-center pointer' onClick={onClick}>
      <div className='pt-4'>
        <input type='checkbox' checked={selected}/>
      </div>

      <div className='ml-8 font-16 bold'>{label}</div>
    </Container>
  )
}

export default CheckBox