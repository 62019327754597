import { ic_setting, phone_mock, sample_data1, tablet_mock } from '@/assets';
import { Button, CheckBox, GridBox, Input, Modal, Textarea } from '@/components';
import { axiosAuth } from '@/lib/axios';
import { getTheme } from '@/styles/theme';
import { useEffect, useState } from 'react';



export default function ModalContainer ({
  isEpisodeModalOpen,
  setIsEpisodeModalOpen,
  isEpisodeEditModalOpen, 
  setIsEpisodeEditModalOpen,
  isDeleteModalOpen, 
  setIsDeleteModalOpen,
  isTitleModalOpen, 
  setIsTitleModalOpen,
  keywordInput,
  setKeywordInput,
  isKeywordModalOpen, 
  setIsKeywordModalOpen,
  isGenreModalOpen, 
  setIsGenreModalOpen,
  isWriterNoteOpen, 
  setIsWriterNoteOpen,
  isSummaryOpen, 
  setIsSummaryOpen,
  isShareModalOpen, 
  setIsShareModalOpen,
  isMobilePreviewOpen, 
  setIsMobilePreviewOpen,
  isTabletPreviewOpen, 
  setIsTabletPreviewOpen,
  isBookcoverModalOpen, 
  setIsBookcoverModalOpen,
  episode_title,
  set_episode_title,
  episode_content,
  set_episode_content,
  episode_index,
  set_episode_index,

  detail,
  setDetail,
  uid
}) {
  const [title, setTitle] = useState(detail?.title);
  const [writer_note, set_writer_note] = useState(detail?.writer_note);
  const [genre, setGenre] = useState(detail?.genre);

  const [summary, setSummary] = useState(detail?.summary);

  const [delete_comment, set_delete_comment] = useState('');
  const [delete_novel_title, set_delete_novel_title] = useState('');

  const [imageFile, setImageFile] = useState(null);



  async function copy_sharelink() {
    const shareLink = `https://sullwha.com/novel/${detail?.uuid}`
  
    try {
      // 클립보드 API를 사용하여 shareLink 문자열 복사
      await navigator.clipboard.writeText(shareLink);
      alert('클립보드에 복사되었습니다.');
    } catch (err) {
      // 복사에 실패한 경우, 에러 핸들링
      console.error('Failed to copy:', err);
      alert('클립보드 복사에 실패했습니다.');
    }
  }

  async function bookcoverUpdate() {
    const formData = new FormData();
    formData.append("file", imageFile);
    formData.append("filename", "book_cover.png");
    formData.append("foldername", uid);

    try {
      await axiosAuth.post('/upload/single', formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      return `https://${import.meta.env.VITE_AWS_S3_BUCKET}.s3.ap-northeast-2.amazonaws.com/` + uid + "/book_cover.png";
    }
    catch {
      return detail?.book_cover
    }
  }


  function handleChange(e, onChange) {
    let value = e.target.value;
    // 한글, 영어, 숫자, 느낌표, 물음표, 쉼표, 마침표, 공백을 허용하는 정규 표현식
    const regex = /^[ㄱ-ㅎㅜ가-힣a-zA-Z0-9!?.,\s]*$/;

    if(value.length >= 20) {}
    else if (value.startsWith(' ')) {} 
    else if (regex.test(value) && !value.includes('  ')) {
      // 연속된 공백이 없고, 정규 표현식과 일치하는 경우에만 상태 업데이트
      onChange(value);
    }
  }


  return (
    <>
      <Modal height={500} isOpen={isTitleModalOpen} onClose={() => setIsTitleModalOpen(false)}>
        <div className='text-center font-28 bold mb-40'>제목 수정</div>
        <Textarea placeholder={"제목을 입력해주세요."} value={title} onChange={(e) => handleChange(e, setTitle)}/>
        <div className='w-full mt-40 flex justify-center'>
          <Button width={160} height={28} fontSize={14} onClick={async () => {
            try {
              await axiosAuth.post('/novel/admin/update/title', { 
                novel_id: detail._id,
                title
              })
              setDetail(item => { return { ...item, title }});
              alert('수정되었습니다!')
              setIsTitleModalOpen(false);
            } catch {}
          }}>수정하기</Button>
        </div>
      </Modal>

      <Modal width={1300} height={800} isOpen={isEpisodeModalOpen} onClose={() => setIsEpisodeModalOpen(false)}>
        <div className='text-center font-28 bold mb-40'>회차 등록</div>

        <div className='font-24 bold mb-12'>제목</div>
        <div style={{width: '400px'}}>
          <Input value={episode_title} onChange={e => handleChange(e, set_episode_title)} placeholder={"제목을 입력해주세요."}/>
        </div>
        
        <div className='font-24 bold mb-12 mt-24'>회차</div>
        <div style={{width: '400px'}}>
          <Input value={episode_index} onChange={e => {
            const regex = /^[0-9]*$/;
            let value = e.target.value;

            if(value.length >= 3) {}
            else if (regex.test(value)) {
              set_episode_index(e.target.value);
            }

          }} placeholder={"회차를 입력해주세요."}/>
        </div>
        
        <div className='flex align-center mb-12 mt-24'>
          <div className='font-24 bold flex-1'>내용</div>
          <div className='flex justify-center'>
            <Button width={160} height={28} fontSize={14} onClick={async () => {
              if(episode_title == '' || episode_content == '') return alert('내용을 전부 입력해주세요.');
              try {
                await axiosAuth.post('/episode/admin', { 
                  novel_uuid: uid,
                  novel: detail?._id,
                  title: episode_title,
                  content: episode_content,
                  episode_index
                })
                alert('등록되었습니다!')
                setIsEpisodeModalOpen(false);
                window.location.reload();
              } catch {}
            }}>등록하기</Button>
          </div>
        </div>

        <Textarea style={{height: '380px'}} placeholder={"내용을 입력해주세요."} value={episode_content} onChange={(e) => set_episode_content(e.target.value)}/>
      </Modal>


      <Modal width={1300} height={800} isOpen={isEpisodeEditModalOpen !== null} onClose={() => setIsEpisodeEditModalOpen(null)}>
        <div className='text-center font-28 bold mb-40'>회차 수정</div>

        <div className='font-24 bold mb-12'>제목</div>
        <div style={{width: '400px'}}>
          <Input value={episode_title} onChange={e => handleChange(e, set_episode_title)} placeholder={"제목을 입력해주세요."}/>
        </div>
        
        <div className='font-24 bold mb-12 mt-24'>회차</div>
        <div style={{width: '400px'}}>
          <Input value={episode_index} onChange={e => {
            let value = e.target.value;
            const regex = /^[0-9]*$/;
            
            if(value.length >= 3) {}
            else if (regex.test(value)) {
              set_episode_index(e.target.value);
            }

          }} placeholder={"회차를 입력해주세요."}/>
        </div>
        
        <div className='flex align-center mb-12 mt-24'>
          <div className='font-24 bold flex-1'>내용</div>
          <div className='flex justify-center'>
            <Button width={160} height={28} fontSize={14} onClick={async () => {
              try {
                await axiosAuth.put('/episode/admin', { 
                  episode_uuid: isEpisodeEditModalOpen,
                  title: episode_title,
                  content: episode_content,
                  episode_index
                })
                alert('수정되었습니다!')
                setIsEpisodeModalOpen(null);
                window.location.reload();
              } catch {}
            }}>수정하기</Button>

            <div className='mr-12'/>

            <Button width={160} height={28} fontSize={14} background={'#ff0000'} color='#fff' onClick={async () => {
              try {
                await axiosAuth.post('/episode/delete/admin', { 
                  episode_uuid: isEpisodeEditModalOpen,
                })
                alert('삭제되었습니다!')
                4325433
                setIsEpisodeModalOpen(null);
                window.location.reload();
              } catch {}
            }}>삭제</Button>
          </div>
        </div>

        <Textarea style={{height: '380px'}} placeholder={"내용을 입력해주세요."} value={episode_content} onChange={(e) => set_episode_content(e.target.value)}/>
      </Modal>


      <Modal height={500} isOpen={isWriterNoteOpen} onClose={() => setIsWriterNoteOpen(false)}>
        <div className='text-center font-28 bold mb-40'>작가의말 수정</div>
        <Textarea placeholder={"작가의 말을 입력해주세요."} value={writer_note} onChange={(e) => handleChange(e, set_writer_note)}/>
        <div className='w-full mt-40 flex justify-center'>
          <Button width={160} height={28} fontSize={14} onClick={async() => {
            try {
              await axiosAuth.post('/novel/admin/update/writenote', { 
                novel_id: detail._id,
                writer_note
              })
              setDetail(item => { return { ...item, writer_note }});
              alert('수정되었습니다!')
              setIsWriterNoteOpen(false)
            } catch {}
          }}>수정하기</Button>
        </div>
      </Modal>



      <Modal height={500} isOpen={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)}>
        <div className='text-center font-28 bold mb-24'>소설 데이터 삭제</div>
        <div className='text-center font-18 bold mb-12'>데이터 복구 필요 시 관리자에게 문의하세요.<br/>상황에 따라 복구에 어려움이 있을 수 있습니다.</div>
        
        <Input placeholder={"삭제할 소설의 제목을 확인해주세요."} value={delete_novel_title} onChange={e => handleChange(e, set_delete_novel_title)}/>
        <div className='mb-12'/>
        <Textarea  placeholder={"삭제 사유를 입력해주세요."} value={delete_comment} onChange={e => handleChange(e, set_delete_comment)}/>
        
        <div className='w-full mt-40 flex justify-center'>
          <Button width={160} height={28} fontSize={14} background={'#ff0000'} onClick={async () => {
            try {
              await axiosAuth.post('/novel/admin/delete', { 
                novel_uuid: uid,
                delete_comment,
                delete_novel_title
              })
              alert('삭제되었습니다!');
              window.history.back();
            } catch {}
          }}>삭제하기</Button>
        </div>
      </Modal>



      <Modal height={500} isOpen={isSummaryOpen} onClose={() => setIsSummaryOpen(false)}>
        <div className='text-center font-28 bold mb-40'>줄거리 수정</div>
        <Textarea  placeholder={"줄거리를 입력해주세요."} value={summary} onChange={e => handleChange(e, setSummary)}/>
        <div className='w-full mt-40 flex justify-center'>
          <Button width={160} height={28} fontSize={14} onClick={async () => {
            try {
              await axiosAuth.post('/novel/admin/update/summary', { 
                novel_id: detail._id,
                summary
              })
              setDetail(item => { return { ...item, summary }});
              alert('수정되었습니다!')
              setIsSummaryOpen(false)
            } catch {}
          }}>수정하기</Button>
        </div>
      </Modal>

      <Modal height={500} isOpen={isKeywordModalOpen} onClose={() => setIsKeywordModalOpen(false)}>
        <div className='text-center font-28 bold mb-40'>키워드 수정</div>
        
        <Input background={'#fff'} placeholder={'키워드를 입력해주세요 (ex. #키워드1#키워드2)'} type="text" label=""  value={keywordInput} onChange={e => setKeywordInput(e.target.value)} />
        
        <div className='w-full mt-40 flex justify-center'>
          <Button width={160} height={28} fontSize={14} onClick={async () => {
            try {
              await axiosAuth.post('/novel/admin/update/keywords', { 
                novel_id: detail._id,
                keywords: keywordInput?.slice(1, keywordInput.length)?.split('#')
              })
              setKeywords(keywordInput?.slice(1, keywordInput.length)?.split('#'))
              // setDetail(item => { return { ...item, keywords }});
              alert('수정되었습니다!')
              setIsKeywordModalOpen(false)
            } catch {}
          }}>수정하기</Button>
        </div>
      </Modal>


      <Modal height={500} isOpen={isGenreModalOpen} onClose={() => setIsGenreModalOpen(false)}>
        <div className='text-center font-28 bold mb-40'>장르 수정</div>

        <div className='flex mt-12'>{
          ['로맨스', '로맨스 판타지', 'BL', 'GL', '판타지', '현대 판타지', '무협'].map((item, idx) => (
            <div className='mr-12' key={`genre-${idx}`}>
              <CheckBox label={item} selected={genre?.includes(item)} onClick={() => {
                if(genre?.includes(item))
                  setGenre(genre_list => genre_list.filter(it => it !== item));
                else setGenre(genre_list => [...genre_list, item])
              }}/>
            </div>
          ))
        }</div>

        <div className='w-full mt-40 flex justify-center'>
          <Button width={160} height={28} fontSize={14} onClick={async () => {
            try {
              await axiosAuth.post('/novel/admin/update/genre', { 
                novel_id: detail._id,
                genre
              })
              setDetail(item => { return { ...item, genre }});
              alert('수정되었습니다!')
              setIsGenreModalOpen(false)
            } catch {}
          }}>수정하기</Button>
        </div>
      </Modal>


      <Modal height={600} isOpen={isBookcoverModalOpen} onClose={() => setIsBookcoverModalOpen(false)}>
        <div className='text-center font-28 bold mb-40'>표지 수정</div>
        
        <input type="file" accept="image/*" id="imageUpload" style={{ display: "none" }} onChange={(e) => setImageFile(e.target.files[0])}/>
        
        <label htmlFor='imageUpload' className='flex-center flex-col w-full'>
          <img className='book_cover mb-24 pointer' src={(imageFile ? URL.createObjectURL(imageFile) : null) || detail?.book_cover}/>
        </label>
        <p className='m-0 text-center'>이미지를 클릭해주세요.</p>

        <div className='w-full mt-40 flex justify-center'>
          <Button width={160} height={28} fontSize={14} onClick={async () => {
            try {
              const bookcover_uri = await bookcoverUpdate();

              await axiosAuth.post('/novel/admin/update/bookcover', { 
                novel_id: detail._id,
                book_cover: bookcover_uri
              })
              setImageFile(null)
              setDetail(item => { return { ...item, book_cover: bookcover_uri }});
              alert('수정되었습니다!');
              setIsBookcoverModalOpen(false)
              return window.location.reload();
            } catch {}
          }}>수정하기</Button>
        </div>
      </Modal>



      <Modal height={890} isOpen={isMobilePreviewOpen} onClose={() => setIsMobilePreviewOpen(false)}>
        <div className='text-center font-28 bold mb-40'>미리보기</div>

        <div className='mock_a2 relative'>
          <img src={phone_mock} className='w-full phone_mock'/>
          <iframe 
            className='mock_scroll'
            src={`https://sullwha.com/novel/${uid}`}
          />
        </div>
        <div className='w-full mt-40 flex justify-center'>
          <Button width={160} height={28} fontSize={14}>모바일</Button>
          <div className='mx-8'/>
          <Button background={'#8e8e8e'} hoverBackground={getTheme('primary')} width={160} height={28} fontSize={14} onClick={() => {
            setIsTabletPreviewOpen(true)
            setIsMobilePreviewOpen(false)
          }}>태블릿</Button>
        </div>
      </Modal>


      <Modal width={1200} height={800} isOpen={isTabletPreviewOpen} onClose={() => setIsTabletPreviewOpen(false)}>
        <div className='text-center font-28 bold mb-40'>미리보기</div>

        <div className='mock_a3 relative'>
          <img src={tablet_mock} className='w-full phone_mock'/>
          <iframe 
            className='mock_scroll'
            src={'https://sullwha.com/novel/o7zPCCP3cHFM'}
          />
        </div>
        <div className='w-full mt-40 flex justify-center'>
          <Button background={'#8e8e8e'} hoverBackground={getTheme('primary')} width={160} height={28} fontSize={14} onClick={() => {
            setIsTabletPreviewOpen(false)
            setIsMobilePreviewOpen(true)
          }}>모바일</Button>
          <div className='mx-8'/>
          <Button width={160} height={28} fontSize={14}>태블릿</Button>
        </div>
      </Modal>

      <Modal height={360} isOpen={isShareModalOpen} onClose={() => setIsShareModalOpen(false)}>
        <div className='text-center font-28 bold mb-40'>작품 공유</div>
        <Input value={`https://sullwha.com/novel/${detail?.uuid}`}/>
        <div className='mt-48'/>
        <Button style={{
          width:'100%'
        }} onClick={copy_sharelink}>공유 링크 복사</Button>
      </Modal>
    </>
  )
}