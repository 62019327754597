import React, { useLayoutEffect } from 'react';
import styled from 'styled-components';
import Header from './Header';
import { Navigate, useLocation } from 'react-router-dom';
import Navigation from './Navigation';


const AuthWhiteList = ['/login']

const Wrapper = ({children}) => {
  const isAuthenticated = localStorage.getItem('user_access_token');
  const location = useLocation();
  const authentication = !AuthWhiteList.includes(location.pathname)

  console.log(location.pathname)

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

  if(authentication) {
    // 인증이 반드시 필요한 페이지
    return isAuthenticated === null ? <Navigate to='/login'/> : children;  
  } else {
    // 인증이 반드시 필요 없는 페이지
    return isAuthenticated === null ? children : <Navigate to="/"/>;
  }
} 


const Container = styled.div`
  background-color: #f2f3f6;
`

const InnerContainer = styled.div`
  width: 100%;
  min-width: 900px;
  display: flex;
  background: #fff;
  min-height: 100vh;

  .main_section {
    width: 100%;
  }
`

function LayoutContainer({ children }) {
  
  const isAuthenticated = localStorage.getItem('admin_data');

  return (
    <>
      {isAuthenticated && 
        <Container id="#wrap" className='flex-center'>
          <Wrapper>
            <Header/>

            <InnerContainer id='inner_container'>
              <Navigation/>

              <div className='main_section flex-1'>
                {children}
              </div>
            </InnerContainer>

          </Wrapper>
        </Container>
      }
      
      {!isAuthenticated && <div style={{ backgroundColor: '#F5F5F5'}} id="#wrap">{children}</div>}
    </>
  );
}

export default LayoutContainer;
