import { Button, Input, LabelInput, Modal, Pagination, Textarea } from '@/components'
import { axiosAuth } from '@/lib/axios'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { formatDateYYMD } from '@/utils/format'
import moment from 'moment'
import 'moment-timezone'
moment.tz.setDefault('Asia/Seoul');

const Container = styled.div`
  width: 100%;
  padding-top: 132px;
`


export default function Page () {

  const [list, setList] = useState([])
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [totalCount, setTotalCount] = useState(1)


  useEffect(() => {
    getData();
  }, [page])

  async function getData() {
    const { data: { data, total, totalCount }} = await axiosAuth.get(`/writer/list/${page}`)

    setTotalCount(totalCount);
    setTotalPage(total);
    
    
    const new_list = [...data];

    setList(new_list);
  }

  const [isOpen, setIsOpen] = useState(false)

  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')

  const [description, setDescription] = useState('')

  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')


  async function submit() {
    if(
      password !== '' && 
      password === confirmPassword && 
      name !== '' && 
      email !== ''
    ) {
      let nowDate = moment().format('YYYYMMDD-HH:mm:ss')

      await axiosAuth.post(`/writerauth/register`, {
        email, password, name, phone, description,
        writerType: 'normal', 
        role: 'user',
        terms: [{ 
          "private": true,
          "privateAgreeTime": nowDate,
          "service": true, 
          "serviceAgreeTime": nowDate,
          "marketing": true,
          "marketingAgreeTime": nowDate
        }],
      })

      alert('등록되었습니다.');
      setIsOpen(false);
    }
    else {
      alert('필수 데이터를 전부 입력해주세요.')
    } 
  }

  return (
    <Container className='px-24'>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <div className='text-center font-28 bold mb-40'>작가 등록</div>

        <Input background={'#fff'} placeholder={'작가님의 E-mail을 입력해주세요.'} type="text" label=""  value={email} onChange={e => setEmail(e.target.value)}/>
        <div className='mb-18'/>

        <Input background={'#fff'} placeholder={'비밀번호를 입력해주세요.'} type="password" label="새 비밀번호" value={password} onChange={e => setPassword(e.target.value)}/>
        <div className='mb-18'/>

        <Input background={'#fff'} placeholder={'비밀번호를 한 번 더 입력해주세요.'} type="password" label="새 비밀번호 확인"  value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)}/>
        <div className='mb-18'/>
        
        <Input background={'#fff'} placeholder={'작가님의 필명을 입력해주세요.'} type="text" label="" value={name} onChange={e => setName(e.target.value)}/>
        <div className='mb-18'/>

        <Input background={'#fff'} placeholder={'작가님의 전화번호를 입력해주세요. (필수 아님)'} type="text" label=""  value={phone} onChange={e => setPhone(e.target.value)} />
        <div className='mb-18'/>

        <Textarea placeholder={"비고를 입력해주세요."} value={description} onChange={e => setDescription(e.target.value)}/>

        <div className='w-full mt-18'>
          <Button style={{
            width:'100%'
          }} onClick={submit}>등록</Button>
        </div>
      </Modal>


      <div className='bold font-24 c-black pl-26'>작가 목록</div>

      
      <div className='w-full flex justify-end pr-16 mt-24'>
        <div className='flex align-center flex-1'>

        </div>

        {/* TODO 검색 구현 */}
        {/* <div style={{ width: '300px'}}>
          <Input placeholder={"검색어를 입력해주세요."}/>
        </div> */}
      </div>


      <div className='w-full mt-20' style={{ borderBottom: '1px solid #e2e2e2'}}>
        {list?.map((item, key) => (
          <WriterItem {...item} key={`writer-${item?._id}-${key}`}/>
        ))}
      </div>

      
      <div className='w-full flex justify-end pr-16 mt-24'>
        <Button style={{}} onClick={() => setIsOpen(true)} width={140} height={40}>신규 등록</Button>
        <div className='ml-8'/>
        <Button style={{}} onClick={() => alert('준비중입니다.')} width={140} height={40} background={'#000'}>CSV 다운로드</Button>
      </div>

      <Pagination
        currentPage={page}
        onClickPage={setPage}
        totalPage={totalPage}
        marginTop={48}
      />

    </Container>
  )
}


const WriterItemContainer = styled.div`
  width: 100%;  
  display: flex;
  align-items: center;
  border-top: 1px solid #e2e2e2;
  padding: 28px 24px;


  .list-header {
    border: solid 1px #8a8a8a;
    border-radius: 7px 7px 0 0;
    border-bottom: 0;
    height: 42px;
  }

  .novel-list {
    border: solid 1px #8a8a8a;
  }
`

const WriterItem = ({ ...writer }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const [email, setEmail] = useState(writer?.email ?? '')
  const [name, setName] = useState(writer?.name ?? '')
  const [phone, setPhone] = useState(writer?.phone ?? '')

  const [description, setDescription] = useState(writer?.description ?? '')

  
  return (
    <WriterItemContainer>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className='text-center font-28 bold mb-40'>작가 상세</div>

        <Input background={'#fff'} type="text" label="" value={name} readOnly/>
        <div className='mb-18'/>

        <Input background={'#fff'} type="text" label=""  value={phone} readOnly/>
        <div className='mb-18'/>
        
        <Input background={'#fff'} type="text" label=""  value={email} readOnly/>
        <div className='mb-18'/>

        {writer?.novels?.length > 0 && <div className='list-header bold flex-center w-full'>작품 목록</div>}
        {writer?.novels?.length > 0 && <div className='novel-list w-full mb-18'>
          {
            writer?.novels?.map((item, key) => (
              <div className='w-full flex align-center px-16 py-12' key={`${writer?._id}-${key}`}>
                <div className=''>{item?.title}</div>
                <div className='font-12 ml-8'>{formatDateYYMD(item?.created_date)}</div>

                <div className='flex-1'/>
                <Link to={`/novel/${item?.uuid}`} className='bold'>작품 상세</Link>
              </div>
            ))
          }
        </div>}

        <Textarea placeholder={"비고를 입력해주세요."} value={description} onChange={e => setDescription(e.target.value)} readOnly/>
      </Modal>


      <div className='bold font-16' style={{flex: 2}}>필명: <span className='bold-400'>{name}</span></div>
      <div className='bold font-16' style={{flex: 5}}>최신 연재작: <span className='bold-400'>{writer?.novels.length > 0 ? writer?.novels[0]?.title : '-'}</span></div>
      <div className='c-primary pointer font-18 bold ml-24' onClick={() => setIsModalOpen(true)}>상세</div>
    </WriterItemContainer>
  ) 
}