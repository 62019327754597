import { Button, CheckBox, Input, LabelInput, Modal, Pagination, Textarea } from '@/components'
import { axiosAuth } from '@/lib/axios'
import { useEffect, useRef, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { unparse } from 'papaparse';

const Container = styled.div`
  width: 100%;
  padding-top: 132px;

  .search_box {
    top: calc(100% + 4px);
    min-height: 100px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #8a8a8a;
    border-radius: 7px;
    z-index: 10;
  }
`


export default function Page () {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const genre_query = searchParams.get('genre');

  const [list, setList] = useState([])
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)


  useEffect(() => {
    getData();
  }, [page, genre_query])

  async function getData() {
    const { data: { data, total, totalCount }} = await axiosAuth.get(`/novel/list/${page}/${genre_query}`)

    setTotalPage(total)
    setList(data)
  }

  const [isOpen, setIsOpen] = useState(false)

  const [title, setTitle] = useState('')
  const [genre, setGenre] = useState([])
  const [keywordInput, setKeyWordInput] = useState('')



  async function submit() {
    if(
      keywordInput && 
      title &&  
      genre.length && 
      writers.length
    ) {
      await axiosAuth.post(`/novel/admin`, {
        has_contract: true,
        writers: writers.map((item) => item._id),
        title,    
        keywords: keywordInput?.slice(1, keywordInput.length)?.split('#'),
        genre
      })
      
      alert('등록되었습니다.')
      setIsOpen(false);
    }
    else {
      alert('필수 데이터를 전부 입력해주세요.')
    } 
  }

  const [search, setSearch] = useState('')
  const [writerSearchList, setWriterSearchList] = useState([])
  
  const [writers, setWriters] = useState([])

  useEffect(() => {
    if(search.length >= 1)
      getWriterData()
  }, [search])

  async function getWriterData() {
    const { data } = await axiosAuth.get(`/writer/search/${search}`);
    setWriterSearchList(data)
  }


  async function downloadCSV() {
    if(list.length <= 0) return alert('데이터를 불러오고 있습니다.');

    let new_list = [];
    
    for(let i = 0; i < list.length; i++) {
      let obj = {
        "제목": list[i].title,
        "작가": list[i].writer.map(item => item.name).join(', '),
        "장르": list[i].genre.join(', '),
        "표지": list[i]?.book_cover,
        "고유번호": list[i].uuid,
        "댓글 수": list[i]?.commentCount ?? 0,
        "좋아요 수": list[i]?.likeCount ?? 0,
        "키워드": list[i]?.keywords.join(', '),
      }; 
      new_list.push(obj)
    }

    const csv = unparse(new_list);

    // BOM을 추가하여 UTF-8 인코딩 문제 해결
    const bom = '\uFEFF';
    const csvWithBOM = bom + csv;

    // Blob 객체를 생성하여 CSV 문자열을 파일로 변환
    const blob = new Blob([csvWithBOM], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    
    // 다운로드 링크 생성 및 클릭 이벤트 발생
    const link = document.createElement('a');
    link.href = url;
    link.download = 'export.csv';  // 다운로드할 파일 이름

    link.click();
    URL.revokeObjectURL(url);
  }

  return (
    <Container className='px-24'>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <div className='text-center font-28 bold mb-40'>작품 등록</div>
        <Input background={'#fff'} placeholder={'제목을 입력해주세요.'} type="text" label="" value={title} onChange={e => setTitle(e.target.value)}/>
        <div className='mb-18'/>

        <div className='w-full relative'>
          <Input background={'#fff'} placeholder={'작가님의 필명을 입력해주세요.'} type="text" label="" value={search} onChange={e => setSearch(e.target.value)}/>
          {search.length > 0 && <div className='absolute search_box py-4'>
            {writerSearchList.length === 0 && <div className='font-18 py-38 text-center' style={{color: '#888'}}>검색된 데이터가 없습니다.</div>}
            {writerSearchList?.map((item, idx) => (
              <div className='px-16 py-4 pointer bold' key={item.name + idx} onClick={() => {
                setWriters(writer_list => [...writer_list, item])
                setSearch('')
              }}>{item.name}</div>
            ))}
          </div>}
        </div>
        {writers?.length > 0 &&
        <div className='mb-18 mt-4 px-12 flex align-center'>
          {writers?.map((item, idx) => (
            <div className='font-14 pointer underline mr-8' key={item.name + idx} onClick={() => {
              setWriters(writer_list => writer_list.filter((wri) => wri.name !== item.name))
            }}>{item.name}</div>
          ))}
        </div>
        }
        
        <div className='mb-18'/>
        


        <div className='font-20 bold'>장르를 선택해주세요 <span className='font-14'>(복수 선택가능)</span></div>
        <div className='flex mt-12'>{
          ['로맨스', '로맨스 판타지', 'BL', 'GL', '판타지', '현대 판타지', '무협'].map((item, idx) => (
            <div className='mr-12' key={`genre-${idx}`}>
              <CheckBox label={item} selected={genre.includes(item)} onClick={() => {
                if(genre.includes(item))
                  setGenre(genre_list => genre_list.filter(it => it !== item));
                else setGenre(genre_list => [...genre_list, item])
              }}/>
            </div>
          ))
        }</div>
        <div className='mb-18'/>

        
        <Input background={'#fff'} placeholder={'키워드를 입력해주세요 (ex. #키워드1#키워드2)'} type="text" label=""  value={keywordInput} onChange={e => setKeyWordInput(e.target.value)} />
        <div className='mb-18'/>

        <div className='flex-center c-primary font-24 bold py-60'>
          그 외 상세설정은 상세 페이지에서 추가해주세요.
        </div>

        <div className='w-full mt-18'>
          <Button style={{
            width:'100%'
          }} onClick={submit}>등록</Button>
        </div>
      </Modal>


      <div className='bold font-24 c-black pl-26'>작품 목록 - {['전체', '로맨스', '로맨스 판타지', 'BL', 'GL', '판타지', '현대 판타지', '무협'][genre_query]}</div>
      <div className='w-full flex justify-end mt-24'>
        <div className='flex align-center flex-1'>

        </div>

        {/* TODO 검색 구현 */}
        {/* <div style={{ width: '300px'}}>
          <Input placeholder={"검색어를 입력해주세요."}/>
        </div> */}
      </div>


      <div className='w-full mt-20' style={{ borderBottom: '1px solid #e2e2e2'}}>
        {list?.map((item, key) => (
          <NovelItem {...item} key={`writer-${key}-${item?._id}`}/>
        ))}
      </div>

      <div className='w-full flex justify-end pr-16 mt-24'>
        <Button style={{}} onClick={() => setIsOpen(true)} width={140} height={40}>작품 등록</Button>
        <div className='ml-8'/>
          <Button style={{}} onClick={downloadCSV} width={140} height={40} background={'#000'}>CSV 다운로드</Button>
      </div>


      <Pagination
        currentPage={page}
        onClickPage={setPage}
        totalPage={totalPage}
        marginTop={48}
      />

    </Container>
  )
}


const NovelItemContainer = styled.div`
  width: 100%;  
  display: flex;
  align-items: center;
  border-top: 1px solid #e2e2e2;
  padding: 28px 24px;


  .novel_cover {
    width: 78px;
    height: 115px;
  }
`

const NovelItem = (novel) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  
  async function copy_sharelink() {
    const shareLink = `https://sullwha.com/novel/${novel?.uuid}`

    try {
      // 클립보드 API를 사용하여 shareLink 문자열 복사
      await navigator.clipboard.writeText(shareLink);
      alert('클립보드에 복사되었습니다.');
    } catch (err) {
      // 복사에 실패한 경우, 에러 핸들링
      console.error('Failed to copy:', err);
      alert('클립보드 복사에 실패했습니다.');
    }
  }

  return (
    <NovelItemContainer>
      <Modal height={360} isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className='text-center font-28 bold mb-40'>작품 공유</div>
        <Input value={`https://sullwha.com/novel/${novel?.uuid}`}/>
        <div className='mt-48'/>
        <Button style={{
          width:'100%'
        }} onClick={copy_sharelink}>공유 링크 복사</Button>
      </Modal>
      {novel?.book_cover && <img src={novel?.book_cover} className='novel_cover mr-28' alt=''/>}
      
      <div className='flex-1'>
        <div className='bold mb-10'>{novel?.title}</div>
        <div className='font-14 mb-28'>{novel?.writer?.map(item => item?.name)?.join(', ')}</div>
        <div className='font-14'>{novel?.genre.join(', ')}</div>
      </div>

      <div className='flex-center flex-col'> 
        <div className='font-18 bold pointer' onClick={() => setIsModalOpen(true)}>공유 링크</div>
        <Link className='font-18 bold mt-24' to={`/novel/${novel?.uuid}`}>작품 상세 보기</Link>
      </div>
    </NovelItemContainer>
  ) 
}