import { getUserData } from '@/utils'
import { Button, Input, LabelBox, LabelInput, Modal } from '@/components'
import { useState } from 'react'
import styled from 'styled-components'
import { axiosAuth } from '@/lib/axios'


const Container = styled.div`
  width: 100%;

  .innerContainer {
    background-color: #fff;
    width: 100%;
    border-radius: 20px;

    .titleBox {
      width: 100%;
      padding-bottom: 24px;
      border-bottom: 1px solid #B0ABB5;
    }
  }
`


export default function MyPage () {
  const [isOpen, setIsOpen] = useState(false)
  const [user, ] = useState(getUserData())
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')


  async function submit() {
    if(password !== confirmPassword) return alert('비밀번호 확인이 같지 않습니다.')
    try {
      await axiosAuth.post(`/apiv/admin/change/password`, {
        _id: user._id,
        password
      })

      alert('유저 비밀번호를 변경했습니다.')
    }
    catch {
      alert('비밀번호 변경에 실패했습니다');
    }
    setIsOpen(false)
  }

  return (
    <Container>
      
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <div className='text-center font-28 bold mb-40'>비밀번호 변경</div>

        <LabelInput background={'#fff'} placeholder={'비밀번호를 입력해주세요.'} type="password" label="새 비밀번호" value={password} onChange={e => setPassword(e.target.value)}/>
        <div className='mb-36'/>
        <LabelInput background={'#fff'} placeholder={'비밀번호를 입력해주세요.'} type="password" label="새 비밀번호 확인"  value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)}/>
        <div className='w-full mt-100'>
          <Button style={{
            width:'100%'
          }} onClick={submit}>변경 완료</Button>
        </div>
      </Modal>
      
      <div className='innerContainer px-36 py-24'>
        <div className='flex-center titleBox mb-42'>
          <div className='font-20 bold'>계정 정보</div>
          <div className='flex-1'/>
        </div>


        <div className='font-18 medium mb-20'>기본 정보</div>
        <LabelBox label="아이디">
          <Input value={user?.name} readOnly={true}/>
        </LabelBox>

        <LabelBox label="비밀번호">
          <Button width={59} height={34} fontSize={14} onClick={() => setIsOpen(true)}>변경</Button>
        </LabelBox>
      </div>
    </Container>
  )
}


