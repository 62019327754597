import { ic_setting, phone_mock, sample_data1, tablet_mock } from '@/assets';
import { Button, CheckBox, GridBox, Input, Modal, Textarea } from '@/components';
import Switch from '@/components/Switch';
import { useHideLayout } from '@/hooks';
import { axiosAuth } from '@/lib/axios';
import { SampleNovel } from '@/static/SampleNovel'
import { getTheme } from '@/styles/theme';
import { formatDateYMD, formatDateYYMD, formatNumber } from '@/utils/format';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components'
import ModalContainer from './modal';


const Container = styled.div`
  width: 100%;
  padding-top: 132px;
  padding-bottom: 132px;

  .book_cover {
    width: 200px;
    height: 300px;
  }

  .novel_info_box {
    padding: 28px 38px;
    background-color: #f2f3f6;
    height: fit-content;
  }

  .episode_list {
    width: 100%;
    border-top: 1px solid #e2e2e2;
  }

  .episode_item {
    border-bottom: 1px solid #e2e2e2;
  }

  
  .mock_a2{
    width: 335px;
    height: 667px;
    margin: 0 auto;
    
    .mock_scroll {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
      overflow-y: auto;
    }

    .mock_scroll::-webkit-scrollbar {
      display: none; /* Chrome, Safari, Opera*/
    }
    .mock_scroll {
      position: absolute;
      top: 12px;
      left: 5.5%;
      width: 89%;
      height: calc(100% - 22px);
      border-radius: 32px;
      overflow-y: auto;
      border: 0;
    }
    .phone_mock {
      width: 100%;
      height: 100%;
    }
  }




   
  .mock_a3{
    width: 1000px;
    height: 567px;
    margin: 0 auto;
    
    .mock_scroll {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
      overflow-y: auto;
    }

    .mock_scroll::-webkit-scrollbar {
      display: none; /* Chrome, Safari, Opera*/
    }
    .mock_scroll {
      border: 0;
      position: absolute;
      top: 26px;
      left: 19%;
      width: 68%;
      height: calc(100% - 50px);
      border-radius: 10px;
      overflow-y: auto;
    }
    .phone_mock {
      width: 100%;
      height: 100%;
    }
  }
`



export default function Page () {
  const { uid } = useParams()

  const navigate = useNavigate()

  const [detail, setDetail] = useState(null);
  const [list, setList] = useState([]);
  
  const [isEpisodeModalOpen, setIsEpisodeModalOpen] = useState(false);
  const [isEpisodeEditModalOpen, setIsEpisodeEditModalOpen] = useState(null);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [isTitleModalOpen, setIsTitleModalOpen] = useState(false);
  const [isKeywordModalOpen, setIsKeywordModalOpen] = useState(false);
  const [isGenreModalOpen, setIsGenreModalOpen] = useState(false);

  const [isWriterNoteOpen, setIsWriterNoteOpen] = useState(false);
  const [isSummaryOpen, setIsSummaryOpen] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);

  const [isMobilePreviewOpen, setIsMobilePreviewOpen] = useState(false);
  const [isTabletPreviewOpen, setIsTabletPreviewOpen] = useState(false);

  const [isBookcoverModalOpen, setIsBookcoverModalOpen] = useState(false);


  const [keywords, setKeywords] = useState([]);
  const [keywordInput, setKeywordInput] = useState('');


  useEffect(() => {
    getData();
    getNovelEpisodeList();
  }, [])


  const [visible, setVisible] = useState();

  async function getData() {
    try {
      const { data } = await axiosAuth.get(`/novel/detail/${uid}`);
  
      if(!data) {
        alert('작품 상세를 불러오지 못했습니다.');
        return window.history.back();
      }
      else {  
        setKeywords(data?.keywords);
        if(data?.keywords?.filter(item => item)?.length > 0) setKeywordInput('#' + data?.keywords.join('#'))
        setVisible(data?.visible);
        setDetail(data);
      }
    }
    catch {
      alert('작품 상세를 불러오지 못했습니다.');        
      return window.history.back();
    }
  }
  
  async function  getNovelEpisodeList() {
    const { data } = await axiosAuth.get(`/episode/list/${uid}`);
    if(data.length > 0) setList(data);
    else setList([])
  }


  const [episode_title, set_episode_title] = useState('');
  const [episode_content, set_episode_content] = useState('');
  const [episode_index, set_episode_index] = useState(1);

  
  useHideLayout('bottomNavigation', true)
  return (
    <Container className=''>
      {detail && <ModalContainer
        isEpisodeModalOpen={isEpisodeModalOpen}
        setIsEpisodeModalOpen={setIsEpisodeModalOpen}
        isEpisodeEditModalOpen={isEpisodeEditModalOpen} 
        setIsEpisodeEditModalOpen={setIsEpisodeEditModalOpen}
        isDeleteModalOpen={isDeleteModalOpen} 
        setIsDeleteModalOpen={setIsDeleteModalOpen}
        isTitleModalOpen={isTitleModalOpen} 
        setIsTitleModalOpen={setIsTitleModalOpen}
        isKeywordModalOpen={isKeywordModalOpen} 
        setIsKeywordModalOpen={setIsKeywordModalOpen}
        isGenreModalOpen={isGenreModalOpen} 
        setIsGenreModalOpen={setIsGenreModalOpen}
        isWriterNoteOpen={isWriterNoteOpen} 
        setIsWriterNoteOpen={setIsWriterNoteOpen}
        isSummaryOpen={isSummaryOpen} 
        setIsSummaryOpen={setIsSummaryOpen}
        isShareModalOpen={isShareModalOpen} 
        setIsShareModalOpen={setIsShareModalOpen}
        isMobilePreviewOpen={isMobilePreviewOpen} 
        setIsMobilePreviewOpen={setIsMobilePreviewOpen}
        isTabletPreviewOpen={isTabletPreviewOpen} 
        setIsTabletPreviewOpen={setIsTabletPreviewOpen}
        isBookcoverModalOpen={isBookcoverModalOpen} 
        setIsBookcoverModalOpen={setIsBookcoverModalOpen}
        episode_title={episode_title}
        set_episode_title={set_episode_title}
        episode_content={episode_content}
        set_episode_content={set_episode_content}
        episode_index={episode_index}
        set_episode_index={set_episode_index}
        setKeywordInput={setKeywordInput}
        keywordInput={keywordInput}
        detail={detail}
        setDetail={setDetail}
        uid={uid}
      />}

      <div className='flex w-full mb-80 px-100'>
        <div className='flex-col align-center mr-50'>
          <img className='book_cover mb-24' src={detail?.book_cover}/>
          <Button width={160} height={28} fontSize={14} onClick={() => setIsBookcoverModalOpen(true)}>표지 수정</Button>
          <div className='mb-20'/>
          <Button width={160} height={28} fontSize={14} onClick={() => setIsMobilePreviewOpen(true)}>미리보기</Button>
        </div>

        <div className='flex-1'>
          <div className='bold font-30 mb-20'>
            {detail?.title}
            <img src={ic_setting} className='ml-8 pointer' onClick={() => setIsTitleModalOpen(true)} style={{width: '21px', height: '21px'}}/>  
          </div>
          
          <div className='mb-28 font-20 flex align-center'>
            {detail?.writer?.map(item => item)?.join(', ')}
            {/* <img src={ic_setting} className='ml-8 pointer' onClick={() => setIsSummaryOpen(true)} style={{width: '21px', height: '21px'}}/>     */}
          </div>

          <div className='font-18 bold mb-20 flex align-center'>
            {detail?.genre?.join(', ')}
            <img src={ic_setting} className='ml-8 pointer' onClick={() => setIsGenreModalOpen(true)} style={{width: '21px', height: '21px'}}/>    
          </div>

          <div className='font-18 bold mb-28 flex aling-center'>
            {/* {detail?.keywords?.map(item => `#${item}`)?.join(' ')} */}
            {keywords?.map(item => `#${item}`)?.join(' ')}
            <img src={ic_setting} className='ml-8 pointer' onClick={() => setIsKeywordModalOpen(true)} style={{width: '21px', height: '21px'}}/>  
          </div>

          <div style={{width: '246px'}}>
            <GridBox col={2} gap={16}>
              <Button width={130} height={28} fontSize={14} onClick={() => setIsShareModalOpen(true)}>공유 링크 복사 </Button>
              {list.length > 0 && <Button width={130} height={28} fontSize={14} onClick={() => navigate(`/detail/${uid}`)}>상세 데이터</Button>}
              <Button width={130} height={28} fontSize={14} onClick={() => alert('잘못된 접근입니다.')}>독자 정보</Button>
              <Button width={130} height={28} fontSize={14} onClick={() => alert('잘못된 접근입니다.')}>댓글 목록</Button>
              <Button width={130} height={28} fontSize={14} background={'#ff0000'} color='#fff' onClick={() => setIsDeleteModalOpen(true)}>삭제</Button>
              <Button width={130} height={28} fontSize={14} onClick={() => alert('잘못된 접근입니다.')}>런칭</Button>
            </GridBox>
          </div>
        </div>

        <div className='novel_info_box'>
          <div className='bold mb-12'>조회수: <span>{formatNumber(detail?.viewCount)}회</span></div>
          <div className='bold mb-12'>좋아요: <span>{formatNumber(detail?.likeCount)}회</span></div>
          <div className='bold mb-12'>공유 횟수: <span>{formatNumber(detail?.shareCount)}회</span></div>
          <div className='bold mb-12'>생성일자: <span>{formatDateYMD(detail?.created_date)}</span></div>
          {/* <div className='bold mb-12'>생성자: <span className='bold'>차재훈</span></div> */}
          <div className='bold mb-12'>마지막수정일자: <span>{formatDateYMD(detail?.updated_date)}</span></div>
          <div className='bold mb-60'>히스토리: <span className='bold pointer' onClick={() => alert('잘못된 접근입니다.')}>확인하기</span></div>
          
          <div className='flex align-center'>
            <div className='bold mr-8'>작품 공개/비공개</div>
            <Switch defaultChecked={visible} onClick={async () => {
              try {
                await axiosAuth.post('/novel/admin/update/visible', { 
                  novel_id: detail._id,
                  visible: !visible
                })
                setVisible(!visible);
              } catch {}
            }}/>
          </div>
        </div>

      </div>
      
      <div className='px-100 w-full'>
        <div className='font-20 bold mb-12 flex items-center'>줄거리: <img src={ic_setting} className='ml-8 pointer' onClick={() => setIsSummaryOpen(true)} style={{width: '21px', height: '21px'}}/></div>
        <div className='font-18 mb-30 pl-18 ellipsis-2'>{detail?.summary}</div>
      </div>

      
      <div className='px-100 w-full'>
        <div className='font-20 bold mb-8 flex items-center'>작가의 말: <img src={ic_setting} className='ml-8 pointer' onClick={() => setIsWriterNoteOpen(true)} style={{width: '21px', height: '21px'}}/></div>
        <div className='font-18 mb-68 pl-18 ellipsis-2'>{detail?.writer_note}</div>
      </div>

      <div className='flex flex-center mb-20 px-42'>
        <div style={{width: '130px'}}/>
        <div className='flex-1'/>

        <div className='flex justify-center align-end'>
          <div className='bold font-20 mr-12 ml-40'>회차목록</div>
          <div className=''>(총 {list.length}화)</div>
        </div>

        <div className='flex-1'/>
        <Button width={130} height={28} fontSize={14} onClick={() => setIsEpisodeModalOpen(true)}>회차 등록</Button>
      </div>

      <div className='episode_list'>
        {
          list?.map((item, idx) => (
            <div className='episode_item w-full py-28 pl-100 pr-68 flex align-center' key={'episode-' + idx}>
              {/* <div className='font-20 bold'>{item}화</div> */}
              <div className='font-20 bold mr-12'>{item.title} - {item.episode_index}회차</div>
              {/* <img className='mx-12 pointer' onClick={() => (true)} style={{width: '21px', height: '21px'}} src={ic_setting}/> */}
              <div className='font-14'>{formatDateYMD(item?.created_date)}</div>

              <div className='flex-1'/>
              <div className='font-18 bold pointer' onClick={() => {
                set_episode_content(item.content);
                set_episode_title(item.title);
                set_episode_index(item.episode_index);
                setIsEpisodeEditModalOpen(item.uuid)
              }}>회차 수정</div>
            </div>
          ))
        }
      </div>
    </Container>
  )
}