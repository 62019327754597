import { ic_detail_grid1, ic_detail_grid2, ic_detail_grid3, ic_detail_grid4, phone_mock, sample_data1, sample_data2 } from '@/assets'
import { Button, GridBox, Modal } from '@/components'
import { formatTime } from '@/utils/format'
import styled from 'styled-components'


const Container = styled.div`
  .mock_a1{
    width: 400px;
    height: 800px;

    margin: 0 auto;
    
    .mock_scroll {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
      overflow-y: auto;
    }

    .mock_scroll::-webkit-scrollbar {
      display: none; /* Chrome, Safari, Opera*/
    }
    .mock_scroll {
      position: absolute;
      top: 19px;
      left: 5.5%;
      width: 89%;
      height: calc(100% - 40px);
      border-radius: 44px;
      overflow-y: auto;
    }
    .phone_mock {
      width: 100%;
      height: 100%;
    }
  }

  .inner_book_cover {
    width: 64%;
    max-width: 200px;
    border-radius: 10px;
  }
`


export default function BounceRateComponent ({
  detail,
  episodeDetail,
  isModalOpen,
  setIsModalOpen,
  isModalOpen2,
  setIsModalOpen2
}) {
  // content_slice_data, // 문장들
  // scene_read_percent, // 문장 타임스탬프
  // scene_read_percent_max_value // 문장 최댓값

  console.log(episodeDetail)

  return (
    <>
      <Modal height={800} isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}> 
        <div className='w-full flex'>
          <div className='mock_a2 relative'>
            <img src={phone_mock} className='w-full phone_mock'/>
            <div className='mock_scroll'>
              <img src={sample_data2} className='w-full'/>
            </div>
          </div>
          
          <div className='flex-1 ml-80'>
            <div className='bold font-36 pt-24 text-center'>{detail?.title}</div>
            <div className='mt-16 text-center font-18'>프롤로그</div>

            <GridBox col={1} gap={24} className='mt-40 px-32'>
              <div className="gridItem1 flex align-center" style={{padding: '8px 30px'}}>
                <div className='flex-1'>
                  <div className='font-24 bold pt-8 c-primary mb-16'>{formatTime(episodeDetail?.average_read_time ?? 0)}</div>
                  <div className='c-grey2 font-12'>평균 읽은 시간</div>
                </div>

                <div className='blueCircle'>
                  <img className='blueIcon' src={ic_detail_grid1}/>
                </div>
              </div>
              <div className="gridItem1 flex align-center" style={{padding: '8px 30px'}}>
                <div className='flex-1'>
                  <div className='font-24 bold pt-8 c-primary mb-16'>{formatTime(episodeDetail?.total_read_time ?? 0)}</div>
                  <div className='c-grey2 font-12'>총 읽은 시간</div>
                </div>

                <div className='blueCircle'>
                  <img className='blueIcon' src={ic_detail_grid2}/>
                </div>
              </div>
              <div className="gridItem1 flex align-center" style={{padding: '8px 30px'}}>
                <div className='flex-1'>
                  <div className='font-24 bold pt-8 c-primary mb-16'>{episodeDetail?.average_read_percent}%</div>
                  <div className='c-grey2 font-12'>평균 완독률</div>
                </div>

                <div className='blueCircle'>
                  <img className='blueIcon' src={ic_detail_grid3}/>
                </div>
              </div>
              <div className="gridItem1 flex align-center" style={{padding: '8px 30px'}}>
                <div className='flex-1'>
                  <div className='font-24 bold pt-8 c-primary mb-16'>{episodeDetail?.episode_view_count}회</div>
                  <div className='c-grey2 font-12'>회차 조회수</div>
                </div>

                <div className='blueCircle'>
                  <img className='blueIcon' src={ic_detail_grid4}/>
                </div>
              </div>
            </GridBox>
            
            <div className='w-full px-30 pb-30 bold mt-24' >
              <Button style={{width: '100%'}} height={56} fontSize={20} onClick={() => {
                setIsModalOpen(false)
                setIsModalOpen2(true)
              }}>독자이탈분석</Button>
            </div>
          </div>
        </div>
      </Modal>


      <Modal width={1100} height={780} isOpen={isModalOpen2} onClose={() => setIsModalOpen2(false)}>
        <div className='w-full flex'>
          <div className='mock_a2 relative'>
            <img src={phone_mock} className='w-full phone_mock'/>
            <div className='mock_scroll'>
              <img src={sample_data1} className='w-full'/>
            </div>
          </div>
          
          <div className='flex-1 ml-80'>
            <div className='bold font-36 pt-24 text-center'>{detail?.title}</div>
            <div className='mt-16 text-center font-18'>프롤로그</div>

            <GridBox col={1} gap={16} className='mt-40 px-32'>
              <div className='bo w-full py-14 px-16'>
                <div className='flex align-end mb-16 '>
                  <div className='font-18 bold ' style={{color: '#8d9498'}}>최다 이탈 문장 1</div>
                  <div className='font-12 ml-10' style={{ color: '#8d9498'}}>약 16%의 독자가 이 문장에서 이탈했습니다. </div>
                </div>
                <div className='font-16 bold px-24 line-32'>
                  하지만, 거듭되는 저를 향한 부름을 거역할 수도 없는 노릇이어서. 연후는 찬찬히 몸을 돌렸다. 그의 두 손에는 정액으로 푹 젖어버린 속옷이 불안한 감정과 함께 쥐어진 채였다.
                </div>
              </div>

              <div className='bo w-full py-14 px-16'>
                <div className='flex align-end mb-16 '>
                  <div className='font-18 bold ' style={{color: '#8d9498'}}>최다 이탈 문장 2</div>
                  <div className='font-12 ml-10' style={{ color: '#8d9498'}}>약 16%의 독자가 이 문장에서 이탈했습니다. </div>
                </div>
                <div className='font-16 bold px-24 line-32'>
                  “형 생각하면서 자위한 거야?”<br/>
                   “……나 그렇게까지 쓰레기 아니야.”<br/>
                   “그럼, 자다가?”
                </div>
              </div>
            </GridBox>
            
            <div className='w-full px-30 pb-30 bold mt-24' >
              <Button style={{width: '100%'}} height={56} fontSize={20} onClick={() => {
                setIsModalOpen(true)
                setIsModalOpen2(false)
              }}>상세로 돌아가기</Button>
            </div>
          </div>
        </div>
      </Modal>


      <Container> 
        <div className='flex-1 bo'>
          <div className='font-20 bold mb-24 py-34 px-44' style={{color: '#8d9498'}}>독자가 머무른 시간들</div>


          <div className='mock_a1 relative'>
            <img src={phone_mock} className='w-full phone_mock'/>
            <div className='mock_scroll'>
              {/* 데이터 */}
              <div className='py-40'>
                <div className='w-full flex-center mb-40'>
                  <img className='inner_book_cover' src={detail?.book_cover}/>
                </div>
                
                {
                  episodeDetail?.content_slice_data?.map((item, idx) => (
                    <EpisodeSectionItem 
                      idx={idx}
                      item={item}
                      isLastItem={idx === episodeDetail?.content_slice_data?.length - 1}
                      read_percent={(episodeDetail?.scene_read_percent[idx] / episodeDetail?.scene_read_percent_max_value) * 100}
                      key={`novel-index-${idx}`}  
                    />
                  ))
                }
              </div>
            </div>

          </div>

          <div className='w-full flex-center px-30 pb-30 bold mt-70' >
            {/* <Button style={{width: '100%'}} height={86} fontSize={30} onClick={() => setIsModalOpen(true)}>독자이탈분석</Button> */}
          </div>
        </div>
      </Container>
    </>
  )
}



const EpisodeSectionItem = ({ item, isLastItem, read_percent, idx }) => {

  const gradientStyle = {
    background: `linear-gradient(to right, #4388ff ${read_percent}%, #ffffff 0%)`,
    fontSize: '16px',
    width: '100%',
    lineHeight: '30px',
    whiteSpace: 'pre-line'
  };


  return (
    <div className=''>
      <div className='pre-line font-16 w-full line-30 px-12' style={gradientStyle}>
        {/* 구간 출력 */}
        {item} 
        {/* 마지막 구간이면 공백을 출력하지 않음 */}
        {!isLastItem && 
          <>
            <br/>
            <br/>
          </>
        }
      </div>
    </div>
  )
}