import { Button, Dropdown, GridBox, Modal, Textarea } from '@/components';
import { useHideLayout } from '@/hooks';
import { SampleNovel } from '@/static/SampleNovel'
import { foramtDateKR, formatNumber, formatTime } from '@/utils/format';
import { useEffect, useState } from 'react';
import styled from 'styled-components'
import React from 'react';
import { Line } from 'react-chartjs-2';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler } from 'chart.js';
import { ic_detail_grid1, ic_detail_grid2, ic_detail_grid3, ic_detail_grid4, ic_detail_grid5, ic_detail_grid6, phone_mock, sample_data1, sample_data2 } from '@/assets';
import { useParams } from 'react-router-dom';
import { axiosAuth } from '@/lib/axios';
import BounceRateComponent from './BounceRate';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, ArcElement, Title, Tooltip, Legend, Filler);

const Container = styled.div`
  width: 100%;
  padding: 132px 100px;
  background-color: #f2f3f6;

  .blueCircle {
    width: 50px;
    height: 50px;
    background-color: #4388ff;
    border-radius: 50% 0 50% 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    .blueIcon {
      width: 24px;
      
    }
  }

  .chart-container canvas {
  }
  .c-grey {
    color: #b3b7ba !important;
  }
  
  .c-grey2 {
    color: #8d9498;
  }
  
  .bg-white {
    background-color: #fff;
  }

  .bo {
    border-radius: 5px;
    border: solid 1px #ebebeb;
    background-color: #fff;
  }

  .book_cover {
    width: 134px;
    height: 200px;
  }

  .gridItem1{
    border-radius: 5px;
    border: solid 1px #ebebeb;
    background-color: #fff;
    padding-top: 21px;
    padding-bottom: 21px;
  }

  .cw100 canvas{
    width: 100% !important;
  }
  /* .gridBox2 {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 28px;
  } */


  .mock_a2{
    width: 290px;
    height: 580px;
    
    .mock_scroll {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
      overflow-y: auto;
    }

    .mock_scroll::-webkit-scrollbar {
      display: none; /* Chrome, Safari, Opera*/
    }
    .mock_scroll {
      position: absolute;
      top: 12px;
      left: 5.5%;
      width: 89%;
      height: calc(100% - 22px);
      border-radius: 32px;
      overflow-y: auto;
    }
    .phone_mock {
      width: 100%;
      height: 100%;
    }
  }
`

const centerTextPlugin = {
  id: 'centerTextPlugin',
  afterDraw(chart) {
    const ctx = chart.ctx;
    const centerConfig = chart.config.options.elements.center;
    const fontStyle = 'NanumSquareNeoOTF';
    const txt = centerConfig.text;
    const color = centerConfig.color || '#000';
    const sidePadding = centerConfig.sidePadding || 20;
    const sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2)
    ctx.font = '16px';

    //Get the width of the text
    const stringWidth = ctx.measureText(txt).width;
    const elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;

    // Find out how much the font can grow in width.
    const widthRatio = elementWidth / stringWidth;
    const newFontSize = Math.floor(30 * widthRatio);
    const elementHeight = (chart.innerRadius * 2);

    // Pick a new font size so it will not be larger than the height of label.
    const fontSizeToUse = Math.min(newFontSize, elementHeight);

    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    const centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
    const centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
    ctx.font = 20 + "px " + fontStyle;
    ctx.fillStyle = color;

    // Draw text in center
    ctx.fillText(txt, centerX, centerY);
  }
};


const genderData = {
  labels: ['여성', '남성'],
  datasets: [
    {
      data: [73, 27],
      backgroundColor: ['#FF6384', '#36A2EB'],
      hoverOffset: 4,
      cutout: '70%',
    },
  ],
};


const ageData = {
  labels: ['10대', '20대', '30대', '40대', '50대 이상'],
  datasets: [
    {
      data: [13, 22, 40, 35, 0],
      backgroundColor: ['#FF9F40', '#FFCD56', '#4BC0C0', '#36A2EB', '#9966FF'],
      hoverOffset: 4,
      cutout: '70%',
    },
  ],
};



const data = {
  labels: ['4월', '5월', '6월', '7월', '8월', '9월', '10월'],
  datasets: [
    {
      label: '판매량',
      data: [400, 500, 450, 600, 550, 600, 650],
      fill: true,
      backgroundColor: 'rgba(75,192,192,0.2)',
      borderColor: 'rgba(75,192,192,1)',
      tension: 0.4
    }
  ]
};


const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: '누적 조회수 추이',
    },
  },
};



const LineChart = () => {
  return <Line data={data} options={options} />;
};

export default function Page () {
  const { uid } = useParams();

  const [detail, setDetail] = useState(null);
  const [isWriterNoteOpen, setIsWriterNoteOpen] = useState(false);
  const [isSummaryOpen, setIsSummaryOpen] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);


  const [episodeList, setEpisodeList] = useState([])
  const [selectedEpisode, setSelectedEpisode] = useState();

  const [graphDetail, setGraphDetail] = useState();

  const [episodeDetail, setEpisodeDetail] = useState(null)
  useHideLayout('bottomNavigation', true);

  useEffect(() => {
    document.getElementById('inner_container').style.maxWidth = '100%'

    getData();
    getNovelEpisodeList();
    getNovelGraphData();

    return () => document.getElementById('inner_container').style.maxWidth = '1200px'
  }, []);

  useEffect(() => {
    getEpisodeDetailData();
  }, [selectedEpisode])

  async function getData() {
    const { data } = await axiosAuth.get(`/novel/data/${uid}`);
    setDetail(data)
  }

  async function  getNovelEpisodeList() {
    const { data } = await axiosAuth.get(`/episode/list/${uid}`);
    if(data.length > 0) {
      const fixed_data = data.map((item) => {
        return {
          ...item,
          name: item.title
        }
      })

      setSelectedEpisode(fixed_data[0])
      setEpisodeList(fixed_data);
    }
    else setEpisodeList([])
  }


  
  async function getEpisodeDetailData() {
    if(!selectedEpisode) return;

    const { data } = await axiosAuth.post(`/novel/data/episode`, {
      uuid: selectedEpisode?.uuid,
      episode_content: selectedEpisode?.content
    });

    if(data) setEpisodeDetail(data);
    else return;
  }

  async function getNovelGraphData() {
    const { data } = await axiosAuth.get(`/novel/view/graph/${uid}`);

    if(data) setGraphDetail(data);
    else return;
  }

  
  function getPercent(str) {
    if(str === "NaN%") return "계산 중";
    else if(str === "Infinity%") return "계산 중";

    let num = parseFloat(str);
    if (isNaN(num)) return "계산 중";
    
    
    else return (num.toFixed(2)) + "%";
  }


  return (
    <Container className=''>
      


      



      <div className='w-full flex align-center'>
        <div className='bo flex bg-white px-24 pt-26 pb-36 flex-1'>
          <img className='book_cover mr-30' src={detail?.book_cover}/>
          <div className='flex-col'>
            <div className='c-primary font-24 bold mb-12'>데이터 보고서</div>
            <div className='c-grey font-24 bold mb-18'>{detail?.title}</div>
            <div className='c-grey font-18 mb-14'>{detail?.writer?.map(item => item.name)?.join(', ')}</div>
            <div className='c-grey font-18 mb-42'>개제일: {foramtDateKR(detail?.created_date)}</div>
            <div className='c-grey font-18'>{detail?.keywords?.map(item => `#${item}`)?.join(' ')}</div>
          </div>
        </div>

        <GridBox col={3} gap={28} className='flex-1 ml-28'>
          <div className="gridItem1 pl-34">
            <div className='font-30 pt-8 c-primary mb-16'>{detail?.alarm_count}</div>
            <div className='c-grey2'>선작수</div>
          </div>
          <div className="gridItem1 pl-34">
            <div className='font-30 pt-8 c-primary mb-16'>{getPercent(detail?.average_continuity_view_percent)}</div>
            <div className='c-grey2'>평균연독률</div>
          </div>
          <div className="gridItem1 pl-34">
            <div className='font-30 pt-8 c-primary mb-16'>{detail?.likeCount}</div>
            <div className='c-grey2'>평균추천(좋아요) 수</div>
          </div>
          <div className="gridItem1 pl-34">
            <div className='font-30 pt-8 c-primary mb-16'>계산 중</div>
            <div className='c-grey2'>방문자</div>
          </div>
          <div className="gridItem1 pl-34">
            <div className='font-30 pt-8 c-primary mb-16'>계산 중</div>
            <div className='c-grey2'>순 방문자</div>
          </div>
          <div className="gridItem1 pl-34">
            <div className='font-30 pt-8 c-primary mb-16'>{detail?.shareCount}</div>
            <div className='c-grey2'>공유 횟수</div>
          </div>
        </GridBox>
      </div>

      <GridBox col={4} gap={28} className='flex-1 mt-28'>
        <div className="gridItem1 px-30 flex align-center">
          <div className='flex-1'>
            <div className='font-30 pt-8 c-primary mb-16'>{formatTime(detail?.total_read_time ?? 0)}</div>
            <div className='c-grey2'>총 읽은 시간</div>
          </div>

          <div className='blueCircle'>
            <img className='blueIcon' src={ic_detail_grid1}/>
          </div>
        </div>

        <div className="gridItem1 px-30 flex align-center">
          <div className='flex-1'>
            <div className='font-30 pt-8 c-primary mb-16'>{formatTime(detail?.average_read_time ?? 0)}</div>
            <div className='c-grey2'>평균 읽은 시간(회차당)</div>
          </div>

          <div className='blueCircle'>
            <img className='blueIcon' src={ic_detail_grid2}/>
          </div>
        </div>

        <div className="gridItem1 px-30 flex align-center">
          <div className='flex-1'>
            <div className='font-30 pt-8 c-primary mb-16'>{detail?.complete_reading_percent ? detail?.complete_reading_percent?.toFixed(1) + "%" : "계산 중"}</div>
            <div className='c-grey2'>완독 비율</div>

          </div>
          <div className='blueCircle'>
            <img className='blueIcon' src={ic_detail_grid3}/>
          </div>
        </div>

        <div className="gridItem1 px-30 flex align-center">
          <div className='flex-1'>
            <div className='font-30 pt-8 c-primary mb-16'>{detail?.average_view_count?.toFixed(1)}회</div>
            <div className='c-grey2'>평균 화차 조회수</div>
          </div>

          <div className='blueCircle'>
            <img className='blueIcon' src={ic_detail_grid4}/>
          </div>
        </div>


        <div className='bo px-14 py-14 cw100' style={{ gridColumn: "span 2", height: '400px' }}>
          <div className='mb-16'>일간 조회수 추이를 계산중입니다.</div>
          <div className='h-full blur'>
            <LineChart/>
          </div>
        </div>  

        <div className='bo px-14 py-14 cw100' style={{ gridColumn: "span 2", height: '400px' }}>
          <div className='mb-16'>주간 조회수 추이를 계산중입니다.</div>
          <div className='h-full blur'>
            <LineChart/>
          </div>
        </div>  
      </GridBox>

      <div className='mt-28'/>

      <GridBox col={2} gap={28}>
        <div className='w-full'>
          <div className='flex w-full mb-28'>
            <div className='bo py-34 px-44'>
              <div className='font-20 bold mb-16'>회차 선택</div>
              <div style={{width: '300px'}}>
                <Dropdown options={episodeList} select={selectedEpisode} setSelect={setSelectedEpisode}/>
              </div>
            </div>
          </div>

          <div className='w-full'>
            <div className='flex-1 flex-col'>
              <div className='bo w-full mb-28 py-34 px-44'>
                <div className='font-20 bold mb-24 ' style={{color: '#8d9498'}}>독자가 오래 머무른 장면 1</div>
                <div className='pt-120 font-20 bold px-24 line-32 mb-48'>
                  {episodeDetail?.scene_first_content}
                </div>
                <div className='font-20 bold mb-24 px-24' style={{color: '#8d9498'}}>총 독자분들의 {episodeDetail?.scene_first_percent}%가 이 장면을 인상깊게 읽었습니다.</div>
              </div>

              <div className='bo w-full mb-28 py-34 px-44'>
                <div className='font-20 bold mb-24 ' style={{color: '#8d9498'}}>독자가 오래 머무른 장면 2</div>
                <div className='pt-120 font-20 bold px-24 line-32 mb-48'>
                  {episodeDetail?.scene_second_content}
                </div>
                <div className='font-20 bold mb-24 px-24' style={{color: '#8d9498'}}>총 독자분들의 {episodeDetail?.scene_second_percent}%가 이 장면을 인상깊게 읽었습니다.</div>
              </div>
            </div>
          </div>
        </div>

        <div className='w-full'>
          <BounceRateComponent 
            detail={detail}
            episodeDetail={episodeDetail}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            isModalOpen2={isModalOpen2}
            setIsModalOpen2={setIsModalOpen2}
          />
        </div>
      </GridBox>

    </Container>
  )
}