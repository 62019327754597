import { Link, NavLink } from 'react-router-dom'
import styled from 'styled-components'

import { main_logo } from '@/assets'
import { getUserData } from '@/utils'
import { useUser } from '@/stores'
import { useState } from 'react'

const Container = styled.div`
  width: 100%;
  height: 88px;
  background-color: #fff;
  position: fixed;
  top: 0;
  z-index: 20;
  box-shadow: 0px 9px 16px 0 rgba(0, 0, 0, 0.11);

  .innerHeader {
    width: 100%;
    padding: 0 120px;
    height: 100%;
  }

  
  .logoutButton {
    width: 124px;
    height: 42px;
    border-radius: 42px;
    border: 1px solid #000;
  }
`

const StyledLink = styled(NavLink)`
  
`

export default function Header () {
  const [user, ] = useState(getUserData())

  const { userLogout } = useUser()
  
  return (
    <Container className='flex-center'>
      <div className='innerHeader flex-center'>
        <Link to ='/'>
          <img style={{width: '157px'}} src={main_logo}/>
        </Link>

        <div className='flex-1'></div>


        <div className='flex align-center'>
          <Link to='/mypage' className='underline font-18'>
            {user?.name}
          </Link>

          <div className='ml-20 pointer logoutButton flex-center font-18 bold' onClick={userLogout}>로그아웃</div>
        </div>
      </div>
    </Container>
  )
}

