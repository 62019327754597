import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { useClickOutside } from '@/hooks';
import { dropdown_arrow } from '@/assets/components';
import { getTheme } from '@/styles/theme';

const Container = styled.div`
  width: 100%;
  height: 48px;
  padding: 14px 10px;
  border: 0;
  position: relative;

  .dropImg {
    width: 12px;
  }

  border-radius: 4px;
  border: 1px solid #E8E8E8;
  .dropdown-main {
  }

  .dropdown-menu {
    width: 100%;
    padding: 10px 16px;
    background-color: #fff;
    border: 1px solid #000;
    position: absolute;
    z-index: 999;
    top: calc(100% - 8px);
    left: 0;

    .dropdown-item {
      
    }
    .dropdown-item:hover {
      color: ${getTheme('primary')};
    }
  }
`


function Dropdown({ options, label, style, select, setSelect, dkey, readOnly=false, ...rest }) {
  const [isOpen, setIsOpen] = useState(false)

  const ref = useRef()

  useClickOutside(ref, () => setIsOpen(false))

  return (
    <Container ref={ref} style={style}>
      <div className='flex-center pointer dropdown-main' onClick={() => {
        if(!readOnly) setIsOpen(isOpen => !isOpen)
      }} {...rest}>
        <div className='font-14'>{select?.name ?? label}</div>
        <div className='flex-1'/>
        <img src={dropdown_arrow} className={`dropImg ${isOpen && "flip-v"}`}/>
      </div>

      {(!readOnly && isOpen) &&
        <div className='dropdown-menu' style={style}>
          {options?.map((item, key) => (
            <div className='dropdown-item my-10 font-14 bold pointer' key={`${dkey}-dropdown-item-key-${key}`} onClick={() => {
              setSelect(item)
              setIsOpen(false)
            }}>{item.name}</div>
          ))}
        </div>
      }
    </Container>
  );
}

export default Dropdown;
