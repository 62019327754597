import { Fragment, useState } from 'react'
import './font.css'
import { AppRouter } from './routers/AppRouter'
import GlobalStyles from './styles/GlobalStyles'

function App() {

  return (
    <>
      <AppRouter/>
      <GlobalStyles/>
    </>
  )
}

export default App
