import React from 'react';
import styled from 'styled-components';

import { NavLink, useLocation } from 'react-router-dom';
import { getTheme } from '@/styles/theme';

// import {} from 'assets'; // [INIT_User] 네비게이션 아이콘

const Container = styled.div`
  background-color: #fafafd;

  width: 272px;
  height: 100vh;
  overflow-y: auto;
  padding-top: 94px;
  border-right: 1px solid #e4e4e4;

  .active {
    color: ${getTheme('primary')};
  }

  .navBox {
    height: 98px;
    width: 100%;
    border-bottom: 2px solid #F5F5F5;
  }
`;

const NavList = [
  {
    name: '작품목록',
    list: [
      {
        name: '전체',
        link: '/novel?genre=0'
      },
      {
        name: '로맨스',
        link: '/novel?genre=1'
      },
      {
        name: '로맨스 판타지',
        link: '/novel?genre=2'
      },
      {
        name: 'BL',
        link: '/novel?genre=3'
      },
      {
        name: 'GL',
        link: '/novel?genre=4'
      },
      {
        name: '판타지',
        link: '/novel?genre=5'
      },
      {
        name: '현대 판타지',
        link: '/novel?genre=6'
      },
      {
        name: '무협',
        link: '/novel?genre=7'
      }
    ]
  },
  // {
  //   name: '배너등록',
  //   list: [
  //     {
  //       name: "배너 목록",
  //       link: '/banner'
  //     },
  //   ]
  // },
  {
    name: '일반작가',
    list: [
      {
        name: "작가 목록",
        link: '/writer'
      },
    ]
  },
  // {
  //   name: '개인정보',
  //   list: [
  //     {
  //       name: '수집 개인 정보',
  //       link: '/policy-list'
  //     },
  //   ]
  // },
  {
    name: '기타',
    list: [
      // {
      //   name: '키워드',
      //   link: '/keyword'
      // },
      {
        name: '오픈소스 API',
        link: '/opensource-license'
      },
      {
        name: '신고 댓글 목록',
        link: '/comment-report'
      },
    ]
  },
]


function Navigation() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const genre = searchParams.get('genre');
  
  const getNavLinkClass = (isActive, idx) => {
    // URL의 쿼리 파라미터에 따라 추가적인 조건을 확인
    const isGenreMatch = Number(genre) === idx; // 예시로 genre=0인 경우를 확인

    
    return isActive && isGenreMatch ? "pl-16 block mb-12 medi font-14 active" : "pl-16 block mb-12 medi font-14";
  }; 

  return (
    <Container className='' id="bottomNavigation" >
      <div className='navList pt-20 pl-30'>
        {
          NavList.map((item, idx) => (
            <div className='mb-32' key={`nav-eky-${idx}`}>
              <div className='font-18 bold mb-12'>{item.name}</div>
              {
                item?.list?.map((post, key) => (
                  <NavLink className={({ isActive }) => getNavLinkClass(isActive, key)} to={post.link} key={`nav-${key}-S-${idx}`}>{post.name}</NavLink>
                ))
              }
            </div>
          ))
        }
      </div>
    </Container>
  );
}
export default Navigation;
