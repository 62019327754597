import React, { useState } from 'react';
import styled from 'styled-components';
import { useUser } from '@/stores';
import { Button, LabelInput } from '@/components';
import { main_logo } from '@/assets';

const Container = styled.div`
  width: 100vw;
  height: 100vh;

  .inputBox {
    input {
      background-color: #fff !important;
    }
  }

  
  .logoBar {
    width: 100%;
    height: 94px;
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
  }

  .loginBox {
    width: 606px;
    height: 520px;
    border-radius: 20px;
    background-color: #fff;
    padding: 0 93px;
    padding-top: 57px;
  }
`;

function LoginPage() {
  const { userLocalSignin } = useUser()

  const [state, setState] = useState(true)
  
  const [id, setId] = useState('')
  
  const [password, setPassword] = useState('')

  // TODO 회원 생성에 이은 로그인 로직 추가 필요
  async function getLogin() {
    const result = await userLocalSignin({ id, password })

    if(result) {
      window.location.href ='/'
    }
    else {
      setState(false)
    }
  }


  return (
    <Container className='flex-col flex-center'>
      <div className='inputBox w-100'>
        <div className='flex-center logoBar'>
          <img style={{width: '80px'}} className='' src={main_logo}/>
        </div>
        
        <div className='loginBox'>
          <div className='font-28 bold mb-40 text-center'>로그인</div>
          <LabelInput label={"아이디"} placeholder="아이디를 입력해주세요." value={id} onChange={e => setId(e.target.value)}/>

          <div className='mt-32'/>
          <LabelInput label={"비밀번호"} placeholder="비밀번호를 입력해주세요." value={password} onChange={e => setPassword(e.target.value)} type="password"/>
          
          <div className='mt-58'></div>
          <Button color={'#fff'} fontSize={20} style={{width: '100%'}} onClick={getLogin}>로그인</Button>
          {!state && <div className='font-16 bold text-center mt-16' style={{width: '100%', color: '#ff4545'}}>아이디와 비밀번호를 다시 확인해주세요.</div>}
        </div>
      </div>
    </Container>
  )
}


export default LoginPage